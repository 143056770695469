import React, {useEffect} from "react";
import {removeItem, replaceItem} from "../../Redux/cartReducer";
import {useDispatch, useSelector} from "react-redux";
import Lightbox from "react-awesome-lightbox";
import "react-awesome-lightbox/build/style.css";
import ShoppingCartModule from "../ShoppingCartModule";
import {changeCurrency} from "../../functions/changeCurrency";

function ShoppingCartElement({product, onCounterChange}) {
    const dispatch = useDispatch();
    const [quantity, setQuantity] = React.useState(product.quantity);
    const [accordionConfiguration, setAccordionConfiguration] =
        React.useState(false);

    const [closeLightbox, setCloseLightbox] = React.useState(false);
    const [lightboxSrc, setLightboxSrc] = React.useState("");

    useEffect(() => {

        dispatch(
            replaceItem({
                id: product.cartProductId,
                quantity: quantity,
            }),
        );
    }, [quantity]);


    const handleCounterChange = (value) => {
        onCounterChange(product.id, value);
    };

    const growImage = (e) => {
        setLightboxSrc(e.target.src);
        setCloseLightbox(!closeLightbox);
    };

    const closeGalery = () => {
        setLightboxSrc("");
        setCloseLightbox(!closeLightbox);
    };
    const currency = useSelector((state) => state.language.language);

    const moreDetails = (mobile = false) => (product?.configurations.length > 0 && (
        <div className="drawer__item-configutations">

            <h6
                onClick={() => setAccordionConfiguration(!accordionConfiguration)}
                className="di-flex a-i-center configutations-net"
            >
                <span>Configurations:</span>
                {/*{mobile && <img*/}
                {/*    className={accordionConfiguration ? "active" : ""}*/}
                {/*    src={process.env.PUBLIC_URL + "/img/header__list-arrow.svg"}*/}
                {/*    alt="arrow"*/}
                {/*/>}*/}
            </h6>

            <div
                className={
                    // mobile ?
                    //     accordionConfiguration
                    //         ? "drawer__item-configutations-body accordion-content-c active"
                    //         : "drawer__item-configutations-body accordion-content-c"
                    /*  : */"drawer__item-configutations-body accordion-content-c active"
                }
            >
                {product?.configurations.map((configuration, i) => (
                    <section
                        key={i + "configurationShoppingCart" + configuration.id}
                        className="d-flex a-i-center configutation-item"
                    >
                        <p>{configuration?.title}</p>
                        <span>{configuration?.value}</span>
                    </section>
                ))}
            </div>
        </div>
    ))


    return (
        <div className="shoppingCart__net-item-wr">
            <div className="shoppingCart__net-item">
                <div className="main__wr">

                    <img
                        onClick={(e) => growImage(e)}
                        className="main"
                        src={product.img}
                        alt="miniature"
                    />
                </div>


                <div className="shoppingCart__item-details">
                    <div className="firstColl">
                        <div className="shoppingCart__item-title">
                            <h3>{product?.title}</h3>
                        </div>
                        <div className="shoppingCart__item-quantity d-flex a-i-center w100 j-c-space">
                            <p className="desc">Qty</p>

                            <div className="shoppingCart__quantity-body d-flex a-i-center ">
                          <span
                              onClick={() =>
                                  setQuantity((prev) => (prev > 1 ? prev - 1 : prev))
                              }
                              className="shoppingCart__minus"
                          >
                            -
                          </span>

                                <p onChange={handleCounterChange} className="shoppingCart__count">
                                    {quantity}
                                </p>

                                <span
                                    onClick={() => setQuantity((prev) => prev + 1)}
                                    className="shoppingCart__plus"
                                >
                            +
                          </span>
                            </div>
                        </div>
                        <div className="shoppingCart__main-characteristicts">
                            {product?.color?.name && (
                                <section className="d-flex a-i-center shoppingCart__main-characteristicts-net">
                                    <p className="shoppingCart__main-characteristicts-p">Color: </p>
                                    <p>{product.color.name}</p>
                                    <img
                                        onClick={(e) => growImage(e)}
                                        src={product.color.img}
                                        alt="color"
                                    />
                                </section>
                            )}

                            {product?.material?.name && (
                                <section className="d-flex a-i-center shoppingCart__main-characteristicts-net">
                                    <p className="shoppingCart__main-characteristicts-p">
                                        Material:{" "}
                                    </p>
                                    <p>{product.material.name}</p>
                                    <img
                                        onClick={(e) => growImage(e)}
                                        src={product.material.img}
                                        alt="color"
                                    />
                                </section>
                            )}
                        </div>
                    </div>
                    <div className="middleColl">
                        {moreDetails()}
                    </div>
                    <div className="endColl">
                        <img
                            onClick={() => {
                                dispatch(removeItem(product.cartProductId));
                            }}
                            className="delete"
                            src={process.env.PUBLIC_URL + "/img/drawer__close.svg"}
                            alt="close"
                        />
                        <div className="w100 d-flex j-c-end price">
                            {changeCurrency((product.price * quantity).toFixed(2), currency)} s DPH{" "}
                        </div>
                    </div>


                </div>

            </div>

            <div className="drawer__item-modules">
                {product?.modules?.map((module, i) => (
                    <ShoppingCartModule
                        key={i + "drawerModules" + module.id}
                        module={module}
                        growImage={growImage}
                    />
                ))}
            </div>
            <div className="shoppingCart__detailsMobile">
                {moreDetails(true)}
            </div>

            {closeLightbox && <Lightbox image={lightboxSrc} onClose={closeGalery}/>}
        </div>
    );
}

export default ShoppingCartElement;
