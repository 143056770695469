import React, {useEffect, useMemo, useState} from "react";
import CheckoutItem from "../../Components/CheckoutItem";
import "./checkout.scss";
import {useNavigate} from "react-router-dom";
import Scrollbars from "react-scrollbars-custom";
import Select from "react-select";
import {useDispatch, useSelector} from "react-redux";
import {replaceItem} from "../../Redux/cartReducer";
import axios from "axios";
import {loadStripe} from "@stripe/stripe-js";
import {makeRequest} from "../../Components/MakeRequest";
import {addTimestamp} from "../../Redux/timestampReducer";
import PhoneInput from "react-phone-input-2";
import {changeCurrency} from "../../functions/changeCurrency";
import countryList from "react-select-country-list";

function Checkout({text}) {
    let navigate = useNavigate();
    const dispatch = useDispatch();

    const [modalPreview, setModalPreview] = React.useState(false);

    const products = useSelector((state) => state.cart.products);
    const profile = useSelector((state) => state.login.login);
    const [profileData, setProfileData] = React.useState({});
    const prodocodeInformation = useSelector(
        (state) => state.promocode.promocode,
    );
    const [disabledButton, setDisabledButton] = React.useState(false);

    const [activeButtonData, setActiveButtonData] = useState("getting");

    useEffect(() => {
        if (products.length <= 0) {
            navigate("/");
        }
    }, [products]);

    const handleRadioChange = (event) => {
        const data = event.target.getAttribute("data");
        setActiveButtonData(data);
    };
    const language = useSelector((state) => state.language.language.language);

    const [countryCode, setCountryCode] = useState("");

    useEffect(() => {
        axios("https://ipapi.co/json/")
            .then((response) => {
                const {country_code, country_name, calling_code} = response.data;

                if (country_code.toUpperCase() !== "RU") {
                    setInputPhone("+" + calling_code);
                    setCountryCode(country_code);
                    setInputCountry({label: country_name, value: country_code});
                }
            })
            .catch((error) => {
                console.error(error);
            });
    }, []);

    const [inputFirstName, setInputFirstName] = React.useState("");
    const [inputSecondName, setInputSecondName] = React.useState("");
    const [inputCompanyName, setInputCompanyName] = React.useState("");
    const [inputCountry, setInputCountry] = React.useState("");
    const [inputCity, setInputCity] = React.useState("");
    const [inputRegion, setInputRegion] = React.useState("");
    const [inputZipCode, setInputZipCode] = React.useState("");
    const [inputAdress, setInputAdress] = React.useState("");
    const [inputPhone, setInputPhone] = React.useState("+380");
    const [inputEmail, setInputEmail] = React.useState("");
    const [inputDetails, setInputDetails] = React.useState("");

    const [validityInputName, setValidityInputName] = React.useState(false);
    const [validityInputCountry, setValidityInputCountry] = React.useState(false);
    const [validityInputCity, setValidityInputCity] = React.useState(false);
    const [validityInputZipCode, setValidityInputZipCode] = React.useState(false);
    const [validityInputAdress, setValidityInputAdress] = React.useState(false);
    const [validityInputPhone, setValidityInputPhone] = React.useState(false);
    const [validityInputEmail, setValidityInputEmail] = React.useState(false);

    const countryOptions = useMemo(
        () =>
            countryList()
                .getData()
                .filter((k) => k.value != "RU"),
        [],
    );
    const changeCountryHandler = (value) => {
        setInputCountry(value);
    };

    React.useEffect(() => {
        setInputFirstName(profileData.username || "");
        setInputSecondName(profileData.lastName || "");
        setInputCompanyName(profileData.companyName || "");
        setInputCountry(profileData.country || "");
        setInputCity(profileData.city || "");
        setInputRegion(profileData.region || "");
        setInputZipCode(profileData.zipCode || "");
        setInputAdress(profileData.adress || "");
        setInputPhone(profileData.phone || "");
        setInputEmail(profileData.email || "");
        setInputDetails("");

        // setTimeout(() => {
        //   setModalPreview(!modalPreview);
        // }, 2000);
    }, [profileData]);

    const fetchData = async () => {
        if (profile.length > 0) {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_API_URL}/users/${profile[0].profileId}?populate=*`,

                    {
                        headers: {
                            Authorization: "Bearer " + process.env.REACT_APP_API_TOKEN,
                        },
                    },
                );

                setProfileData(response.data);

                // Перенаправлення на захищену сторінку або виконання інших дій
                // ...
            } catch (error) {
                // Обробка помилок аутентифікації
                console.log(error);
            }
        }
    };

    React.useEffect(() => {
        fetchData();
    }, []);

    const stripePromise = loadStripe(process.env.REACT_APP_PAYMENT_KEY);

    const shipCost = useSelector((state) => state.cart.shipCost);
    const shipType = useSelector((state) => state.cart.shipType);
    const [createOrdersProductsId, setCreateOrdersProductsId] = useState([]);

    const selectedKeys = [
        "id",
        "title",
        "price",
        "quantity",
        "materialArticle",
        "colorArticle",
        "modules",
        "configurations",
        "img",
        "material",
        "color",
        "stockQuantity",
    ];

    const cleanProducts = products.map((item) => {
        const newItem = {};

        for (const key of selectedKeys) {
            if (item[key]) {
                newItem[key] = item[key];
            }
        }

        return newItem;
    });

    const userInformation = {
        Name: inputFirstName,
        LastName: inputSecondName,
        CompanyName: inputCompanyName,
        Country: inputCountry.label,
        City: inputCity,
        Region: inputRegion,
        ZipCode: inputZipCode,
        Adress: inputAdress,
        Phone: inputPhone,
        EmailAddress: inputEmail,
        OrderNotes: inputDetails,
    };

    React.useEffect(() => {
        const ids = products.map((item) => item.id);
        setCreateOrdersProductsId(ids);
    }, [products]);
    const currencyForPay = useSelector(
        (state) => state.language.language.currencyForPay,
    );
    const currency = useSelector((state) => state.language.language);
    const createOrder = async () => {
        const currentTimestamp = Date.now();

        setDisabledButton(!disabledButton);


        try {
            const res = await makeRequest.post("/unpaid-orders", {
                data: {
                    shipCost: +changeCurrency(shipCost, currency, true),
                    shipType: shipType,
                    status: "Pending maneger review",
                    timeStamp: currentTimestamp.toString(),
                    clientInformation: userInformation,
                    commentForOrder: "",
                    products: createOrdersProductsId,
                    totalPrice: +changeCurrency(totalPrice(), currency, true),
                    currency: currencyForPay,
                    productsDetails: cleanProducts,
                    promocodePercent: prodocodeInformation[0]?.promocodePercent
                        ? prodocodeInformation[0]?.promocodePercent.toString()
                        : "0",
                    type: "Not paid",
                },
            });
            setDisabledButton(false);
            if (res.status === 200) {
                setDisabledButton(false);

                dispatch(addTimestamp(currentTimestamp));

                setTimeout(() => {
                    navigate(
                        `/?success=${currentTimestamp.toString()}&email=${inputEmail}`,
                    );
                }, 1000);
            }
        } catch (err) {
            console.log(err);
            setDisabledButton(false);
        }
    };

    const createPayment = async () => {
        const currentTimestamp = Date.now();

        setDisabledButton(!disabledButton);

        try {
            const stripe = await stripePromise;
            const res = await makeRequest.post("/orders", {
                products: products.map(k => {
                    return {...k, price: changeCurrency(k.price, currency, true)}
                }),
                productsId:
                    createOrdersProductsId.length > 1
                        ? createOrdersProductsId
                        : createOrdersProductsId[0],
                email: inputEmail,//profileData.email,
                timestamp: currentTimestamp,
                clientInformation: userInformation,
                shipCost: +changeCurrency(shipCost, currency, true),
                shipType: shipType,
                currency: currencyForPay,
                totalPrice: +changeCurrency(totalPrice(), currency, true),
                promocodePercent: prodocodeInformation[0]?.promocodePercent
                    ? prodocodeInformation[0]?.promocodePercent.toString()
                    : "0",
            });
            dispatch(addTimestamp(currentTimestamp));
            await stripe.redirectToCheckout({
                sessionId: res.data.stripeSession.id,
                customerEmail: profileData.email,
            });
            setDisabledButton(false);
        } catch (err) {
            console.log(err);
            setDisabledButton(false);
        }
    };

    const handlePayment = () => {
        if (inputCountry.length < 2) {
            setValidityInputCountry(true);
        } else {
            setValidityInputCountry(false);
        }

        if (inputFirstName.length < 2) {
            setValidityInputName(true);
        } else {
            setValidityInputName(false);
        }

        if (inputPhone.length < 5) {
            setValidityInputPhone(true);
        } else {
            setValidityInputPhone(false);
        }

        if (inputEmail.length < 5) {
            setValidityInputEmail(true);
        } else {
            setValidityInputEmail(false);
        }

        if (inputAdress.length < 2) {
            setValidityInputAdress(true);
        } else {
            setValidityInputAdress(false);
        }

        if (inputZipCode.length < 2) {
            setValidityInputZipCode(true);
        } else {
            setValidityInputZipCode(false);
        }
        try {
            if (inputCountry === "" && inputCountry.value.length < 2) {
                setValidityInputCity(true);
            } else {
                setValidityInputCity(false);
            }
        } catch (error) {
            setValidityInputCity(true);
        }

        const expression =
            inputCountry != "" &&
            inputCountry.value &&
            inputCountry.value.length >= 2 &&
            inputCity.length >= 2 &&
            inputFirstName.length >= 2 &&
            inputPhone.length >= 5 &&
            inputEmail.length >= 5 &&
            inputZipCode.length >= 2 &&
            inputAdress.length >= 2;

        if (expression) {
            if (activeButtonData === "stripe") {
                createPayment();
            } else {
                createOrder();
            }
        }
    };

    const totalPrice = (type) => {
        let total = 0;
        products.forEach((item) => {
            if (item.stockQuantity === 0) {
                total += (item.quantity * item.price) / 2;
            } else {
                total += item.quantity * item.price;
            }
        });

        if (prodocodeInformation[0]?.promocodePercent) {
            total = +(
                (total * (100 - prodocodeInformation[0]?.promocodePercent)) /
                100
            );
        }
        total += shipCost;

        return total.toFixed(2);
    };

    const [counterValues, setCounterValues] = useState({});

    const handleCounterChange = (productId, value) => {
        setCounterValues((prevValues) => ({
            ...prevValues,
            [productId]: value,
        }));
    };

    const replaceCardParent = (id, count) => {
        dispatch(
            replaceItem({
                id: id,
                quantity: count,
            }),
        );
    };

    useEffect(() => {
        /*var userLang = navigator.language || navigator.userLanguage;
            try {

                let language = '';
                let value = ''
                if (userLang.indexOf('-') === -1) {
                    language = countryList().getLabel(userLang.toUpperCase());
                    value = userLang.toUpperCase()
                } else {

                    value = userLang.toUpperCase().split('-')[0]
                    language = countryList().getLabel(userLang.toUpperCase().split('-')[0]);
                }
                if (language !== undefined && value !== undefined) {

                    setInputCountry({label: language, value: value})
                }
            } catch (error) {

            }*/
    }, []);
    return (
        <div className="checkout content">
            <div className="checkout__details">
                <h4>{text[0]?.attributes?.checkoutTitleLeft}</h4>

                <section className="part d-flex a-i-start j-c-space">
                    <div className="part__item">
                        <label htmlFor="userName">
                            {text[0]?.attributes?.checkoutFirstName}
                        </label>
                        <input
                            className={validityInputName ? "invalid" : ""}
                            value={inputFirstName}
                            onChange={(e) => setInputFirstName(e.target.value)}
                            type="text"
                            id="username"
                        />
                    </div>

                    <div className="part__item">
                        <label htmlFor="lastName">
                            {text[0]?.attributes?.checkoutLastName}
                        </label>
                        <input
                            value={inputSecondName}
                            onChange={(e) => setInputSecondName(e.target.value)}
                            type="text"
                            id="lastName"
                        />
                    </div>
                </section>

                <section>
                    <label htmlFor="companyName">
                        {text[0]?.attributes?.checkoutCompanyName}
                    </label>
                    <input
                        value={inputCompanyName}
                        onChange={(e) => setInputCompanyName(e.target.value)}
                        type="text"
                        id="companyName"
                    />
                </section>

                <section>
                    <label htmlFor="country">
                        {text[0]?.attributes?.checkoutCountry}
                    </label>
                    <Select
                        classNamePrefix={"ownSelect"}
                        styles={{
                            control: (data) => {
                                return {
                                    ...data,
                                    border: validityInputCountry ? "1px solid red" : "",
                                };
                            },
                        }}
                        id="country"
                        className={validityInputCountry ? "invalid" : ""}
                        options={countryOptions}
                        value={inputCountry}
                        onChange={changeCountryHandler}
                    />
                </section>

                <section>
                    <label htmlFor="city">{text[0]?.attributes?.checkoutCity}</label>
                    <input
                        className={validityInputCity ? "invalid" : ""}
                        value={inputCity}
                        onChange={(e) => setInputCity(e.target.value)}
                        type="text"
                        id="city"
                    />
                </section>

                <section>
                    <label htmlFor="region">{text[0]?.attributes?.checkoutRegion}</label>
                    <input
                        value={inputRegion}
                        onChange={(e) => setInputRegion(e.target.value)}
                        type="text"
                        id="region"
                    />
                </section>

                <section>
                    <label htmlFor="zipCode">
                        {text[0]?.attributes?.checkoutZIPcode}
                    </label>
                    <input
                        className={validityInputZipCode ? "invalid" : ""}
                        value={inputZipCode}
                        onChange={(e) => setInputZipCode(e.target.value)}
                        type="text"
                        id="zipCode"
                    />
                </section>

                <section>
                    <label htmlFor="adress">{text[0]?.attributes?.checkoutAdress}</label>
                    <input
                        className={validityInputAdress ? "invalid" : ""}
                        value={inputAdress}
                        onChange={(e) => setInputAdress(e.target.value)}
                        type="text"
                        id="adress"
                    />
                </section>

                <section>
                    <label htmlFor="phone">{text[0]?.attributes?.checkoutPhone}</label>
                    <PhoneInput
                        placeholder="+380 (11) 111 11 **"
                        containerClass={validityInputPhone ? "invalid" : ""}
                        value={inputPhone}
                        excludeCountries={["ru", "kz"]}
                        onChange={setInputPhone}
                        country={countryCode.toLowerCase()}
                    />
                </section>

                {/*// onChange={(e) => setInputPhone(e.target.value)}*/}

                <section>
                    <label htmlFor="email">{text[0]?.attributes?.checkoutEmail}</label>
                    <input
                        className={validityInputEmail ? "invalid" : ""}
                        value={inputEmail}
                        onChange={(e) => setInputEmail(e.target.value)}
                        type="text"
                        id="email"
                    />
                </section>

                <h4 className="m0">{text[0]?.attributes?.checkoutDetailsTitle}</h4>

                <section>
                    <label htmlFor="shoppingDetails">
                        {text[0]?.attributes?.checkoutDetailsLabel}
                    </label>
                    <textarea
                        value={inputDetails}
                        onChange={(e) => setInputDetails(e.target.value)}
                        id="shoppingDetails"
                    ></textarea>
                </section>

                <button
                    disabled={disabledButton}
                    onClick={handlePayment}
                    className="checkout__button w100 cen mobile-version"
                >
                    {activeButtonData === "stripe"
                        ? text[0]?.attributes?.paymentType1ButtonText
                        : text[0]?.attributes?.paymentType2ButtonText}
                </button>
            </div>
            <div className="checkout__order">
                <h4>{text[0]?.attributes?.checkoutTitleRight}</h4>

                <div className="checkout__order-net-wr">
                    <Scrollbars className="checkout__order-net">
                        {products?.map((product) => (
                            <CheckoutItem
                                product={product}
                                key={product.id}
                                onCounterChange={handleCounterChange}
                                replaceCardPerent={replaceCardParent}
                                text={text}
                            />
                        ))}
                    </Scrollbars>
                </div>

                <div className="checkout__price">
                    {prodocodeInformation[0]?.promocodePercent && (
                        <div className="drawer__promo">
                            promo -{prodocodeInformation[0]?.promocodePercent}%
                        </div>
                    )}

                    <div className="checkout__price-total d-flex a-i-center j-c-space">
                        <h5>{text[0]?.attributes?.checkoutTotal}</h5>

                        <h5>{changeCurrency(totalPrice(), currency)}</h5>
                    </div>
                </div>

                <div className="checkout__types">
                    <div className="checkout__types-item  d-flex a-i-center">
                        <input
                            id="paymentype2"
                            data="getting"
                            checked={activeButtonData === "getting"}
                            onChange={handleRadioChange}
                            name="type"
                            type="radio"
                        />

                        <label htmlFor="paymentype2">
                            {text[0]?.attributes?.paymentType2}
                        </label>
                    </div>

                    <div className="checkout__types-item border-bottom d-flex a-i-center">
                        <input
                            data="stripe"
                            id="paymentype1"
                            checked={activeButtonData === "stripe"}
                            onChange={handleRadioChange}
                            name="type"
                            type="radio"
                        />

                        <label htmlFor="paymentype1">
                            {text[0]?.attributes?.paymentType1}
                        </label>
                    </div>
                </div>

                <button
                    disabled={disabledButton}
                    onClick={handlePayment}
                    className="checkout__button w100 cen pc-version"
                >
                    {activeButtonData === "stripe"
                        ? text[0]?.attributes?.paymentType1ButtonText
                        : text[0]?.attributes?.paymentType2ButtonText}
                </button>
            </div>

            <div
                onClick={() => setModalPreview(!modalPreview)}
                className={
                    modalPreview
                        ? "modal__wr modal-checkout d-flex a-i-center j-c-center active"
                        : "modal__wr modal-checkout d-flex a-i-center j-c-center"
                }
            >
                <div className="modal__body">
                    <h4>{text[0]?.attributes.modalGrowImage}</h4>
                </div>
            </div>
        </div>
    );
}

export default Checkout;
