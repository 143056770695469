import React from 'react';
import {useLocation} from 'react-router';

const ScrollToTop: React.FC = () => {
    const {pathname} = useLocation();

    React.useEffect(() => {
        // const el = document.getElementById('wr'); // id of the parent
        //  if (el) {
        /*  el.scrollTo({
            top: 0,
            behavior: 'smooth',
          });*/
        const k = setInterval(() => {
            if (window.screenTop <= 20) {
                clearInterval(k)
            }
            window.scroll(0, 0)
        }, 5)
        setTimeout(() => {
            clearInterval(k)

        }, 1000)
        return () => {
            clearInterval(k)
        }
        //  }
    }, [pathname]);

    return null;
};

export default ScrollToTop;

