import React, {useState} from "react";
import Swiper from "../../Components/SwiperHome/";
import "./home.scss";
import {Link} from "react-router-dom";
import Catalog from "../../Components/Catalog";
import useFetch from "../../Hooks/useFetch";
import Skeleton from "react-loading-skeleton";
import {useSelector} from "react-redux";
import ModalSuccessPayment from "../../Components/ModalSuccessPayment";
import {Helmet} from "react-helmet-async";
import ModalFailPayment from "../../Components/ModalFailPayment";
import ImageTest from "../../Components/SwiperHome/imageTest";
import PcNav from "../ProductsCatalog/components/pcNav";

function Home({text}) {
    const canonicalUrl = window.location.href;

    const languageInformation = useSelector((state) => state.language.language);

    const [categoryOpen, setCategoryOpen] = useState(true);
    const [filtersOpen, setFiltersOpen] = useState(false);
    const [skeletonState, setSkeletonState] = React.useState(true);

    const {data, loading, error} = useFetch(
        process.env.REACT_APP_API_URL +
        `/categories?locale=${languageInformation.language}&populate=*&sort[0]=sort&filters[displayOnHomePage][$eq]=true`,
    ); //

    const {
        data: seo,
        loading: seoLoading,
        error: seoError,
    } = useFetch(
        process.env.REACT_APP_API_URL +
        `/seos?locale=${languageInformation.language}&filters[route]=home&populate=*`,
    );


    // enabled modal component if params exist
    const [successParam, setSuccessParam] = React.useState(false);
    const [failsParam, setFailsParam] = React.useState(false);
    React.useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);

        if (queryParams.get("success") === "false") {

            setFailsParam(true);
        } else if (queryParams.get("success") !== "false") {
            setSuccessParam(true);
        }
    }, []);

    return (
        <div>
            <Helmet>
                <title>{seo[0]?.attributes?.title}</title>
                <meta property="og:title" content={seo[0]?.attributes?.title}/>

                <meta name="description" content={seo[0]?.attributes?.description}/>
                <meta
                    property="og:description"
                    content={seo[0]?.attributes?.description}
                />

                <meta name="keywords" content={seo[0]?.attributes?.keywords}/>

                <link rel="canonical" href={canonicalUrl}/>
                <meta name="Publisher" content="https://shadeko.eu/"/>
            </Helmet>
            <div className={'navTopSpacer'}></div>
            <section className="initial">
                <Swiper/>
            </section>

            <div className={"filterCatalogParent"}>
                <PcNav
                    home={true}
                    data={data}
                    text={text}
                    setFiltersOpen={setFiltersOpen}
                    setCategoryOpen={setCategoryOpen}
                    categoryOpen={categoryOpen}
                    filtersOpen={filtersOpen}
                />
                {/*<div className="mobile__controls d-flex a-i-center mobile_filterButton">*/}
                {/*    <button*/}
                {/*        onClick={() => {*/}
                {/*            setCategoryOpen(!categoryOpen);*/}
                {/*        }}*/}
                {/*    >*/}
                {/*        <span>{text[0]?.attributes?.mobileControlsCategories}</span>*/}
                {/*        <img*/}
                {/*            className={categoryOpen ? "rotate" : ""}*/}
                {/*            src={process.env.PUBLIC_URL + "/img/header__list-arrow.svg?random=1"}*/}
                {/*            alt="arrow"*/}
                {/*        />*/}
                {/*    </button>*/}

                {/*    <button*/}
                {/*        onClick={() => {*/}
                {/*            setFiltersOpen(!filtersOpen);*/}
                {/*        }}*/}
                {/*    >*/}
                {/*        <span>{text[0]?.attributes?.mobileControlsFilter}</span>*/}
                {/*        <img*/}
                {/*            className={filtersOpen ? "rotate" : ""}*/}
                {/*            src={process.env.PUBLIC_URL + "/img/header__list-arrow.svg"}*/}
                {/*            alt="arrow"*/}
                {/*        />*/}
                {/*    </button>*/}
                {/*</div>*/}


                <section
                    className={categoryOpen ? "categories w100" : "categories none w100"}
                    //  className={"categories w100"}
                >
                    {error ? (
                        "error on server"
                    ) : loading ? (
                        <>
                            <div className="skeleton-categories">
                                <Skeleton/>
                            </div>
                            <div className="skeleton-categories">
                                <Skeleton/>
                            </div>
                            <div className="skeleton-categories">
                                <Skeleton/>
                            </div>
                            <div className="skeleton-categories">
                                <Skeleton/>
                            </div>
                            <div className="skeleton-categories">
                                <Skeleton/>
                            </div>
                            <div className="skeleton-categories">
                                <Skeleton/>
                            </div>
                        </>
                    ) : (
                        data.filter(category => {

                            if (category.attributes.img.data != null) {
                                return true
                            }
                            return false
                        }).map((category, index) => (
                            <div
                                key={category.id + category.attributes.name}
                                className="categories__item"
                            >
                                <div
                                    className={
                                        skeletonState
                                            ? "skeleton-load-img active"
                                            : "skeleton-load-img"
                                    }
                                ><img src={'/img/header__logo-short.png'}/></div>

                                <ImageTest
                                    className="main"
                                    src={

                                        category?.attributes?.img?.data?.attributes?.url ? category?.attributes?.img?.data?.attributes?.url : 'null'
                                    }
                                    removeWatermark={true}
                                    alt=""
                                    onLoad={() => {
                                        data.filter(category => {

                                            if (category.attributes.img.data != null) {
                                                return true
                                            }
                                            return false
                                        }).length - 1 === index && setSkeletonState(false);
                                    }}
                                />

                                <Link
                                    to={"/" + category.attributes.url}
                                    className="cen w100 categories__item-link"
                                >
                                    {category.attributes.name}
                                </Link>
                            </div>
                        ))
                    )}
                </section>

                <Catalog

                    hiddenBlankSpace={true}
                    filterOpenState={filtersOpen}
                    text={text}
                    title={text[0]?.attributes?.catalogTitleHome}
                />
            </div>
            {successParam ? <ModalSuccessPayment/> : ""}
            {failsParam ? <ModalFailPayment/> : ""}
        </div>
    );
}

export default Home;
