import React, { useEffect } from "react";
import axios from "axios";

const useFetch = (url, timeout = 0) => {
  const [DATA, setDATA] = React.useState([]);
  const [error, setError] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [pagination, setPagination] = React.useState([]);
  const [lastUrl, setLastUrl] = React.useState();
  useEffect(() => {
    setLoading(true);
    // setTimeout(() => {
    axios
      .get(url, {
        headers: {
          Authorization: "Bearer " + process.env.REACT_APP_API_TOKEN,
        },
      })
      .then((res) => {
        setDATA(res.data.data);
        setPagination(res.data.meta.pagination);
      })
      .catch((error) => {
        setError(true);
      })
      .finally(() => {
        setLoading(false);
      });
    //   }, timeout);
    /*}*/
  }, [url]);
  return { data: DATA, loading, error, pagination };
};
export default useFetch;
