import CollectionElement from "../../Components/CollectionElement";
import Catalog from "../../Components/Catalog";
import React from 'react';
import '../BedroomCollection/bedroomCollection.scss';
import {Link, useParams, useSearchParams} from 'react-router-dom';
import useFetch from '../../Hooks/useFetch';
import Skeleton from 'react-loading-skeleton';
import PcNav from "../ProductsCatalog/components/pcNav";

function Collections({text}) {

    const [filtersOpen, setFiltersOpen] = React.useState(false);
    const {id, name} = useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    const categoryID = searchParams.get('category');
    const {
        data: product,
        loading: loadingProduct,
        error: errorProduct
    } = useFetch(`${process.env.REACT_APP_API_URL}/categories?filters[masterId]=${categoryID ? categoryID : id}&populate[collectionsImg][populate][3]=img&populate[collections][populate][2]=img`);

    let collections = []
    if (product && product[0]) {

        collections = product[0].attributes.collections.data

    }
//collections/Bedroom sets/2?category=2
    //&populate[configurator][populate][1]=img&populate[categoriesInCategories][populate][2]=img&populate[collectionsImg][populate][3]=img
    return (
        <>
            <div className={"filterCatalogParent"}>
                <div className='content collection-wrapper'>


                    <PcNav
                        home={true}
                        //  data={data}
                        skipCategory={true}
                        text={text}
                        setFiltersOpen={setFiltersOpen}
                        // setCategoryOpen={setCategoryOpen}
                        //  categoryOpen={categoryOpen}
                        filtersOpen={filtersOpen}
                    />
                    <div className={'navTopSpacer from600px'}></div>
                    <div className="product__bread-crumbs collection__bread-crumbs d-flex">
                        <Link to='/'>{text[0]?.attributes?.breadCrumbHome}</Link>
                        <span> / </span>
                        <Link
                            to={`/productsCatalog/${product[0]?.attributes?.universalName}/${product[0]?.id}?category=${product[0]?.attributes.masterId}`}> {product[0]?.attributes?.universalName}</Link>
                        <span> / </span>
                        {/*<p>{type ? text[0]?.attributes?.breadCrumbCollectionLuxury : text[0]?.attributes?.breadCrumbCollection}</p>*/}
                        <p>{text[0]?.attributes?.breadCrumbCollection}</p>
                    </div>
                    <div className="catalog__net">
                        {
                            errorProduct ? "error on server" : (
                                loadingProduct ?

                                    <>
                                        <Skeleton className="custom-skeleton"/>
                                        <Skeleton className="custom-skeleton"/>
                                        <Skeleton className="custom-skeleton"/>
                                        <Skeleton className="custom-skeleton"/>
                                        <Skeleton className="custom-skeleton"/>
                                        <Skeleton className="custom-skeleton"/>
                                        <Skeleton className="custom-skeleton"/>
                                    </>
                                    :

                                    collections.map((el) => (
                                        <CollectionElement key={el.id + 'collectionsElements'} el={el}/>
                                    ))
                            )
                        }
                    </div>
                </div>

                <Catalog
                    // notMobile={true}
                    title={product[0] ? product[0].attributes.allProductsText : ''}
                    text={text}
                    hiddenBlankSpace={true}
                    // preCategory={type == 'luxury' ? 'Luxury-dining' : 'Dining'}
                    preSubCategoryIgnore={true}
                    filterDefault={text[0]?.attributes?.breadCrumbCategory6}
                    filterExp='&[filters][collection][type][$containsi]=Dining'
                    filterOpenState={filtersOpen}
                />
            </div>
        </>
    );
}

export default Collections;
