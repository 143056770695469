import React, {useEffect, useRef, useState} from "react";
import "./Calalog.scss";

import CatalogFilter from "../CatalogFilter";
import Card from "../Card";
import Skeleton from "react-loading-skeleton";
import Pagination from "../../Components/Pagination";
import {useSelector} from "react-redux";
import {useLocation, useNavigate, useParams, useSearchParams,} from "react-router-dom";
import useFetch from "../../Hooks/useFetch";
import axios from "axios";
import SelectRows from "./selectRows";

function Catalog(props) {
    //pagination
    const [currentPage, setCurrentPage] = React.useState(1);
    const [currentPageSignal, setCurrentPageSignal] = React.useState(0);
    const [currentPaginationPage, setCurrentPaginationPage] = React.useState(1);
    const [paginationData, setPaginationData] = React.useState([]);
    const componentRef = useRef();
    const [rowsOnPage, setRowsOnPage] = useState(
        localStorage.getItem("rowsInCatalog"),
    );

    function calculateProductsOnPage() {
        switch (localStorage.getItem("rowsInCatalog")) {
            case "3":
                if (productOnPage < 6) setProductOnPage(6);
                break;
            case "4":
                if (productOnPage < 8) setProductOnPage(8);
                break;
            case "5":
                if (productOnPage < 10) setProductOnPage(10);
                break;
            default:
                setProductOnPage(6);
        }
    }

    useEffect(() => {
        calculateProductsOnPage();
    }, []);


    useEffect(() => {
        const read = (event) => {
            setRowsOnPage(localStorage.getItem("rowsInCatalog"));
            calculateProductsOnPage();
        };
        window.addEventListener("storage", read);
        return () => {
            window.removeEventListener("storage", read);
        };
    });

    function returnGridRows() {
        switch (rowsOnPage) {
            case "3":
                return "rows3";
            case "2":
                return "rows2";
            case "4":
                return "rows4";
            case "5":
                return "rows5";
        }
        //return 'rows' + rowsOnPage
    }

    //filters
    const [searchParams, setSearchParams] = useSearchParams();
    const languageInformation = useSelector((state) => state.language.language);
    const [activeIndex, setActiveIndex] = useState(0);
    const [products, setProducts] = useState([]);
    const [productsStatus, setProductsStatus] = useState("loading");

    //data
    const [selectedSort, setSelectedSort] = useState(getValueFromParam("sort"));
    const [selectedCategory, setSelectedCategory] = useState(
        getValueFromParam("category"),
    );
    const [selectedSubcategory, setSelectedSubcategory] = useState(
        getValueFromParam("subcategory"),
    );
    const [selectedShape, setSelectedShape] = useState(
        getValueFromParam("shape"),
    );
    const [selectedMaterial, setSelectedMaterial] = useState(
        getValueFromParam("material"),
    );
    const [selectedColor, setSelectedColor] = useState(
        getValueFromParam("color"),
    );

    const [productOnPage, setProductOnPage] = useState(6);

    useEffect(() => {
        setSelectedCategory(getValueFromParam("category"));
    }, [searchParams.get("category")]);
    const location = useLocation();

    useEffect(() => {
        //    console.log(getValueFromParam('subcategory'));
        if (getValueFromParam("subcategory") > 0) {
            //       console.log("scieżka inna ale parametr");
        } else {
            setSelectedSubcategory(-1);
            //        console.log('Ścieżka URL się zmieniła:', location.pathname);
        }
    }, [location.pathname]);

    const {subcategory, category} = useParams();

    const handlePageChange = (page) => {
        setCurrentPageSignal(page);
        setCurrentPaginationPage(page);
        setCurrentPage(page);
    };

    useEffect(() => {
        if (currentPageSignal == 0) return;
        fetchData();
    }, [currentPageSignal]);
    const clickMoreProduct = () => {
        setProductOnPage((prev) => {
            setCurrentPage(1);
            setCurrentPageSignal(1)
            setCurrentPaginationPage(1);
            return prev + 6;
        });
        /*    setCurrentPage((prev) => {
                                setCurrentPageSignal(prev + 1)
                                return prev + 1

                            });
                    */
    };

    useEffect(() => {
        if (props.preCategory) {
            if (!getValueFromParam("category")) {
                axios(
                    `${process.env.REACT_APP_API_URL}/categories?locale=${languageInformation.language}&filters[universalName][$eq]=` +
                    props.preCategory,
                    {
                        headers: {
                            Authorization: "Bearer " + process.env.REACT_APP_API_TOKEN,
                        },
                    },
                )
                    .then((data) => {
                        let id = data.data.data[0].id;
                        setSelectedCategory(id);
                        setFirstFetch(false);
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            }
        }
    }, [props.preCategory]);

    function getValueFromParam(name) {
        if (name == "subcategory") {
            //  const tmp = searchParams.get(name)
            //  return tmp ? tmp : -1
        }
        const tmp = searchParams.get(name);
        return tmp ? tmp : 0;
    }

    function toggleActiveIndex(index) {
        if (activeIndex === index) {
            setActiveIndex(0);
        } else {
            setActiveIndex(index);
        }
    }

    const {
        data: catalogColors,
        loading: loadingCatalogColors,
        error: errorCatalogColors,
    } = useFetch(
        `${process.env.REACT_APP_API_URL}/colors?locale=${languageInformation.language}&populate=*`,
    );
    const {
        data: catalogShape,
        loading: loadingCatalogShape,
        error: errorCatalogShape,
    } = useFetch(
        `${process.env.REACT_APP_API_URL}/shapesInCategorySubCategory/${selectedCategory}/${selectedSubcategory}?locale=${languageInformation.language}`,
    );

    const {
        data: catalogCategory,
        loading: loadingCatalogCategory,
        error: errorCatalogCategory,
    } = useFetch(
        `${process.env.REACT_APP_API_URL}/categories?locale=${languageInformation.language}&populate=*`,
    );
    let catalogSubcategory = [];
    // let catalogShape = catalogCategory[0]?.attributes?.formas?.data ? catalogCategory[0]?.attributes?.formas.data : []

    if (catalogCategory) {
        try {
            catalogSubcategory = catalogCategory.find(
                (category) => category.id.toString() === selectedCategory.toString(),
            );
            if (!catalogSubcategory) {
                catalogSubcategory = [];
            } else {
                catalogSubcategory = catalogSubcategory.attributes.subcategories.data;
            }
        } catch (error) {
            catalogSubcategory = [];
        }
    }
    const [blockMore, setBlockMore] = useState(false);

    function removeSets(data) {
        return data.filter((element) => {
            if (element.attributes.url == null) return true;
            return element.attributes.url.indexOf("-sets") === -1;
        });
    }

    useEffect(() => {
        if (!getValueFromParam("subcategory")) {
            let mySubCategory = subcategory;
            if (props.preSubCategory) {
                mySubCategory = props.preSubCategory;
            }
            try {
                if (mySubCategory && catalogCategory && catalogCategory.length > 0) {
                    const findCategory = catalogCategory.find((catalog) => {
                        return catalog.id.toString() === selectedCategory.toString();
                    });
                    if (findCategory) {
                        const sub = findCategory.attributes.subcategories.data;
                        let subcategoryTmp = mySubCategory;
                        if (subcategoryTmp.toUpperCase() == "transformers".toUpperCase()) {
                            subcategoryTmp = "Sofabeds";
                        }
                        const findSub = sub.find((sub) => {
                            return (
                                sub.attributes.universalName
                                    .toUpperCase()
                                    .replaceAll(" ", "")
                                    .replaceAll("-", "") ===
                                subcategoryTmp
                                    .toUpperCase()
                                    .replaceAll(" ", "")
                                    .replaceAll("-", "")
                            );
                        });
                        if (findSub) {
                            console.log(findSub.id);
                            setSelectedSubcategory(findSub.id);
                        }
                    }
                } else {
                    //   setSelectedSubcategory(catalogCategory[0].id)
                }
            } catch (error) {
                console.log(error);
            }
        }
        //  }
    }, [
        subcategory,
        catalogCategory,
        catalogSubcategory,
        category,
        languageInformation.language,
    ]);

    const {
        data: catalogMaterial,
        loading: loadingCatalogMaterial,
        error: errorCatalogMaterial,
    } = useFetch(
        `${process.env.REACT_APP_API_URL}/materials?locale=${languageInformation.language}&populate=*`,
    );

    const {
        data: catalogSort,
        loading: loadingCatalogSort,
        error: errorCatalogSort,
    } = useFetch(
        `${process.env.REACT_APP_API_URL}/sorts?locale=${languageInformation.language}&populate=*`,
    );

    const abortControllerRef = useRef(null);

    const fetchData = async (silent = false, superSilent = false) => {
        // Anulowanie poprzedniego zapytania, jeśli istnieje
        if (abortControllerRef.current) {
            abortControllerRef.current.abort();
        }

        // Tworzenie nowego kontrolera anulowania
        const abortController = new AbortController();
        abortControllerRef.current = abortController;

        if (!superSilent) setProductsStatus("loading");
        setBlockMore(true);

        try {
            let exp = "";
            if (selectedCategory)
                exp += "&[filters][categories][masterId]=" + selectedCategory;
            if (selectedSubcategory > 0 /*&& subcategory*/)
                exp += "&[filters][subcategories][masterId]=" + selectedSubcategory;
            if (selectedColor) exp += "&[filters][colors][masterId]=" + selectedColor;
            if (selectedShape)
                exp += "&[filters][shapes][masterId][$eq]=" + selectedShape;
            if (selectedMaterial)
                exp += "&[filters][materials][masterId]=" + selectedMaterial;

            if (selectedSort) {
                const findSort = catalogSort.find((sort) => sort.id == selectedSort);

                if (findSort) {
                    exp += findSort.attributes.body;
                } else {
                    exp += "&sort=createdAt:desc";
                }
            } else {
                exp += "&sort=sort:desc";
            }
            exp +=
                `&pagination[page]=${currentPage}&pagination[pageSize]=` +
                productOnPage;
            //  console.log(exp);
            const response = await fetch(
                `${process.env.REACT_APP_API_URL}/products?locale=${languageInformation.language}&populate=*${exp}`,
                {
                    headers: {
                        Authorization: "Bearer " + process.env.REACT_APP_API_TOKEN,
                    },
                    signal: abortController.signal,
                },
            );

            const data = await response.json();
            setBlockMore(false);
            setProductsStatus("");
            setProducts(data.data);
            if (componentRef.current && !silent) {
                setTimeout(() => {
                    componentRef.current.scrollIntoView({behavior: "smooth"});
                }, 100);
            }

            setPaginationData([]);
            setPaginationData(data);

        } catch (error) {
            // alert(JSON.stringify(error));
            //  console.log(error);
            if (error.name === "AbortError") {
                // console.log('Fetch aborted: ');
            } else {
                console.error(error);
                setProductsStatus("error");
            }
        }
    };
    const [firstFetch, setFirstFetch] = useState(props.preCategory != undefined);

    useEffect(() => {
        //  console.log(selectedSubcategory, subcategory);
        if (selectedSubcategory == 0 && subcategory && subcategory.length > 1) {
            //console.log('lock');
            return;
        }
        if (selectedSubcategory == -1) {
            //      console.log('-1');
            if (!props.preSubCategoryIgnore) {
                return;
            } else {
                setSelectedSubcategory(0);
            }
        }
        fetchData(true);
    }, [selectedSubcategory, selectedCategory]);

    useEffect(() => {
        fetchData(true);
    }, [catalogSort]);
    const [lock, setLock] = useState(true);

    useEffect(() => {
        if (!lock) {
            fetchData(true);
            saveToUrl();
        }
    }, [
        selectedCategory,
        selectedSubcategory,
        selectedSort,
        selectedColor,
        selectedShape,
        selectedMaterial,
    ]);

    useEffect(() => {
        if (!lock) {
            fetchData(true, true);
        }
    }, [productOnPage]);

    const navigate = useNavigate();

    function saveToUrl() {
        let params = {};

        if (selectedSort) params = {sort: selectedSort, ...params};

        if (selectedCategory) params = {category: selectedCategory, ...params};
        if (selectedShape) params = {shape: selectedShape, ...params};

        if (selectedSubcategory)
            if (selectedSubcategory != -1)
                params = {subcategory: selectedSubcategory, ...params};
        if (selectedMaterial) params = {material: selectedMaterial, ...params};
        if (selectedColor) params = {color: selectedColor, ...params};

        const searchParams = new URLSearchParams(params).toString();
        navigate(`?${searchParams}`, {replace: true});
    }

    const [isBelowElement, setIsBelowElement] = useState(false);
    const navRef = useRef(null);
    const fakeNavRef = useRef(null);
    const handleScroll = () => {
        if (fakeNavRef?.current) {
            const elementTop = fakeNavRef?.current.getBoundingClientRect().top;
            if (elementTop <= 0) {
                setIsBelowElement(true);
            } else {
                setIsBelowElement(false);
            }
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        const intervalId = setInterval(() => {
            handleScroll();
        }, 10);
        return () => {
            clearInterval(intervalId);
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);
    const [height, setHeight] = useState(0);

    useEffect(() => {
        try {
            if (navRef) {
                const observer = new ResizeObserver(() => {
                    if (props.primery) {
                        setHeight(0);
                    } else {
                        setHeightInFakeNav();
                    }
                });
                observer.observe(navRef?.current);
                const handleResize = () => {
                    setHeightInFakeNav();
                };
                window.addEventListener("resize", handleResize);
                const handleScroll = () => {
                    setHeightInFakeNav();
                };
                window.addEventListener("scroll", handleScroll);
                const intervalId = setInterval(() => {
                    if (navRef?.current != null) {
                        setHeight(navRef?.current.offsetHeight);
                    }
                }, 100);
                return () => {
                    observer.disconnect();
                    window.removeEventListener("resize", handleResize);
                    window.removeEventListener("scroll", handleScroll);
                    clearInterval(intervalId);
                };
            }
        } catch (error) {
            console.error("Error in useEffect:", error);
        }
    }, [navRef, props.primery]);

    function setHeightInFakeNav() {
        if (navRef?.current != null) {
            setHeight(navRef?.current.offsetHeight);
        }
    }

    useEffect(() => {
        setHeightInFakeNav();
    }, [
        catalogCategory,
        catalogSubcategory,
        catalogSort,
        catalogShape,
        catalogMaterial,
        products,
    ]);
    const heightHeader = useSelector((state) => state.language).headerHeight;
    return (
        <section
            id="catalog"
            ref={componentRef}
            className="catalog"
            onClick={() => setLock(false)}
        >
            <h3 className="catalog__title catalogPadding">{props.title}</h3>
            {!loadingCatalogCategory && (
                <>
                    <div className={"navContainer"}>
                        <div
                            ref={navRef}
                            className={[
                                props.primery ? "primaryNav" : "",
                                !props.primery && isBelowElement ? "changeToFixed" : "",
                                props.notMobile === true
                                    ? ""
                                    : "filterMobile   " +
                                    (!props.filterOpenState ? " mobileHidde" : "mobileOpen"),
                                "realNav",
                            ].join(" ")}
                        >
                            <div
                                style={{top: !props.primery ? heightHeader + 28 + "px" : 0}}
                                className="catalog__filters d-flex a-i-start j-c-start catalogPadding"
                            >
                                <CatalogFilter
                                    toggleActiveIndex={() => toggleActiveIndex(1)}
                                    isActive={activeIndex === 1}
                                    title={props.text[0]?.attributes?.defaultValueSort}
                                    list={[...catalogSort]}
                                    all={props.text[0]?.attributes?.textAllValue}
                                    onChildItemClick={(data) => {
                                        setSelectedSort(data.element.attributes.masterId);
                                    }}
                                    onALLClick={() => {
                                        setSelectedSort();
                                    }}
                                    activeElementId={selectedSort}
                                    type="sort"
                                />

                                <CatalogFilter
                                    toggleActiveIndex={() => toggleActiveIndex(2)}
                                    isActive={activeIndex === 2}
                                    selected={selectedCategory}
                                    title={props.text[0]?.attributes?.defaultValueCategory}
                                    all={props.text[0]?.attributes?.textAllValue}
                                    list={[...catalogCategory]}
                                    onALLClick={() => {
                                        setSelectedSubcategory();
                                        //  saveToUrl()
                                        setSelectedCategory();
                                    }}
                                    onChildItemClick={(data) => {
                                        setSelectedSubcategory();
                                        //     saveToUrl()
                                        setSelectedCategory(data.element.attributes.masterId);
                                        handlePageChange(1);
                                    }}
                                    activeElementId={selectedCategory}
                                    type="category"
                                />

                                <CatalogFilter
                                    toggleActiveIndex={() => toggleActiveIndex(3)}
                                    isActive={activeIndex === 3}
                                    selected={selectedCategory}
                                    block={selectedCategory == 0 || selectedCategory == undefined}
                                    //  block={props.blockSubCategory}
                                    title={props.text[0]?.attributes?.defaultValueSubcategory}
                                    all={props.text[0]?.attributes?.textAllValue}
                                    list={[...removeSets(catalogSubcategory)]}
                                    onALLClick={() => {
                                        setSelectedSubcategory();
                                    }}
                                    activeElementId={selectedSubcategory}
                                    onChildItemClick={(data) => {
                                        handlePageChange(1);
                                        setSelectedSubcategory(data.element.attributes.masterId);
                                    }}
                                    type="subcategory"
                                />

                                <CatalogFilter
                                    toggleActiveIndex={() => toggleActiveIndex(4)}
                                    isActive={activeIndex === 4}
                                    activeElementId={selectedShape}
                                    onChildItemClick={(data) => {
                                        setSelectedShape(data.element.attributes.masterId);
                                    }}
                                    onALLClick={() => {
                                        setSelectedShape();
                                    }}
                                    all={props.text[0]?.attributes?.textAllValue}
                                    title={props.text[0]?.attributes?.defaultValueShape}
                                    list={catalogShape ? [...catalogShape] : []}
                                    type="shape"
                                />
                                <CatalogFilter
                                    toggleActiveIndex={() => toggleActiveIndex(5)}
                                    isActive={activeIndex === 5}
                                    activeElementId={selectedMaterial}
                                    onALLClick={() => {
                                        setSelectedMaterial();
                                    }}
                                    onChildItemClick={(data) => {
                                        setSelectedMaterial(data.element.attributes.masterId);
                                    }}
                                    title={props.text[0]?.attributes?.defaultValueMaterial}
                                    all={props.text[0]?.attributes?.textAllValue}
                                    list={[...catalogMaterial]}
                                    type="material"
                                />

                                <CatalogFilter
                                    toggleActiveIndex={() => toggleActiveIndex(6)}
                                    isActive={activeIndex === 6}
                                    activeElementId={selectedColor}
                                    onChildItemClick={(data) => {
                                        setSelectedColor(data.element.attributes.masterId);
                                    }}
                                    onALLClick={() => {
                                        setSelectedColor();
                                    }}
                                    list={[...catalogColors]}
                                    all={props.text[0]?.attributes?.textAllValue}
                                    title={props.text[0]?.attributes?.defaultValueColour}
                                    type="color"
                                />
                                <SelectRows
                                    text={props.text}
                                    isActive={activeIndex === 7}
                                    toggleActiveIndex={() => toggleActiveIndex(7)}
                                />
                            </div>
                        </div>
                    </div>

                    <div
                        ref={fakeNavRef}
                        style={{height: !props.primery ? height + "px" : 0}}
                        className={[
                            props.notMobile === true
                                ? ""
                                : "filterMobile   " +
                                (!props.filterOpenState ? " mobileHidde" : "mobileOpen"),

                            "fakeNav",
                        ].join(" ")}
                    >
                        <div className="catalog__filters"></div>
                    </div>
                </>
            )}
            <div className="catalog_filter-spacer"></div>
            {productsStatus === "error" ? (
                "Помилка на сервері"
            ) : productsStatus === "loading" ? (
                <div className="catalog__net catalogPadding">
                    <Skeleton className="custom-skeleton"/>
                    <Skeleton className="custom-skeleton"/>
                    <Skeleton className="custom-skeleton"/>
                    <Skeleton className="custom-skeleton"/>
                    <Skeleton className="custom-skeleton"/>
                    <Skeleton className="custom-skeleton"/>
                </div>
            ) : products.length === 0 ? (
                <div className="catalog__empty catalogPadding">
                    <img
                        src={process.env.PUBLIC_URL + "/img/empty-catalog.svg"}
                        alt="header__logo"
                    />
                    <p>{props.text[0]?.attributes?.emptyCatalog}</p>
                </div>
            ) : (
                <div className={"catalog__net catalogPadding " + returnGridRows()}>
                    {products.map((el, i) => (
                        <Card
                            key={el.id + "catalog" + i}
                            product={el}
                            type="catalog"
                            addCart={props.text[0]?.attributes?.buttonAddToCart}
                            addFavorite={props.text[0]?.attributes?.buttonAddToFavorite}
                            deleteFavorite={props.text[0]?.attributes?.deleteFavorite}
                        />
                    ))}
                </div>
            )}

            <div className="catalog__pagination-wr  catalogPadding w100 d-flex a-i-center j-c-center flex-column">
                {(paginationData?.meta?.pagination?.pageCount !== 1 ||
                        paginationData?.meta?.pagination?.pageCount === 0) &&
                    products.length > 0 &&
                    paginationData?.meta?.pagination?.pageCount !== currentPage && (
                        <button
                            disabled={blockMore ? "disabled" : ""}
                            onClick={() => {
                                clickMoreProduct();
                            }}
                            className="product__more-button"
                        >
                            {props.text[0]?.attributes?.buttonMoreProduct}
                        </button>
                    )}

                <div className="catalog__pagination d-flex a-i-center j-c-center">
                    <Pagination
                        currentPageButton={currentPage}
                        currentPage={currentPaginationPage}
                        totalPages={paginationData?.meta?.pagination?.pageCount}
                        onPageChange={handlePageChange}
                    />
                </div>
            </div>
        </section>
    );
}

export default Catalog;
